import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Cookies from 'js-cookie'
import UserHeader from './Parts/Header/Header-User'
import GuestHeader from './Parts/Header/Header-Guest'
import Sidebar from './Parts/Sidebar'
import Footer from './Parts/Footer'
import PrivacyPolicy from './Parts/Sidebar/PrivacyPolicy'
import socket from '../../Socket'
import { Event, decode, encode, wait, isMobile } from '../../Helper'
import WalletAlert from '../Components/User/Wallet/Alert'
import C from '../../Constant'
import Login from './Auth/Login'
import storage from '../../Storage'

import Engine from '../Games/Crash/Engine'
// import Engine from '../Games/Crash/Engine2'
import Canvas from '../Games/Crash/Graphic'
import Mobi from '../Games/Crash/Graphic/Mobi'
import Bet from '../Games/Crash/Bet'
import Bet2 from '../Games/Crash/Bet2'
import Queue from '../Games/Crash/Queue'
import Queue2 from '../Games/Crash/Queue2'
import Players from '../Games/Crash/Players'
import Menus from '../Games/Crash/includes/Menus'
import Affiliate from '../Pages/Affiliate'
import Carousel from './Parts/Carousel'

import HistoryList from '../Games/Crash/includes/HistoryList'

class Index extends React.Component {
  _Mounted = false
  constructor(props) {
    super(props)
    this.state = {
      mobile: false,
      isLogged: false,
      bid: '',
      token: false,
      header: <GuestHeader t={this.props.t} location={this.props.location} />,
      auth: false,
      code:''
    }
    this.handleResize = this.handleResize.bind(this)
  }
  componentWillMount() {
    wait(500).then(() => {
      this._Mounted = true
    })
  }

  componentDidMount() {
    socket.on(C.ONLINE, (status) => this.loginUser(decode(status)))
    Event.on('showAuthModal', (status) => this.activeAuth(status))

    /**
     * Initialize Authentication
     */
    const sessionCookie = Cookies.get('auth')

    if (storage.getKey('token') !== null && sessionCookie) {
      socket.emit(
        C.ONLINE,
        encode({
          jwt_token: storage.getKey('jwt_token'), //fake
          user_token: storage.getKey('user_token'), //fake
          security_key: storage.getKey('security_key'), //fake
          secret_user: storage.getKey('secret_user'), //fake
          secret_realtime: storage.getKey('secret_realtime'), //fake
          client_system: storage.getKey('client_system'), //fake
          token_key: storage.getKey('token_key'), //fakeengine
          secret_token: storage.getKey('secret_token'), //fake
          token: storage.getKey('token'), // REAL
        }),
      )
    }
    wait(600).then(() => {
      this.handleResize()
    })
    window.addEventListener('resize', this.handleResize)

    if (storage.getKey('token')) {
      // this.setState({ isLogged: true, token: storage.getKey('token') })
      this.setState({token: storage.getKey('token') })
    }

   

    wait(500).then(() => {
      Engine.getStatus()
    })
  }

  activeAuth = (status) => {
    this.setState({ auth: status })
  }

  loginUser = (data) => {
    // console.log("check data "+ storage.getKey('token'));
    wait(500).then(() => {
      if (data.status === true) {
        this.setState({
          header: (
            <UserHeader t={this.props.t} location={this.props.location} />
          ),
          isLogged: true,
          code: data.referral_code

        })
        Cookies.set('uid', data.id, { expires: 14 })
        Cookies.set('auth', true, { expires: 14 })
        storage.setKey('name', data.name)
        storage.setKey('email', data.email)
        storage.setKey('credit', data.credit)
        storage.setKey('avatar', data.avatar)
        storage.setKey('friends', data.friends)
        storage.setKey('room', data.room)
        storage.setKey('token', storage.getKey('token'))
        // storage.setKey('referral_code', data.referral_code)
        // this.state.code = data.referral_code;
      } else {
        wait(7000).then(() => {
          localStorage.clear()
        })
      }
    })
  }
  getWidth() {
    return document.documentElement.clientWidth || document.body.clientWidth
  }
  handleResize() {
    if (this.getWidth() < 1540) {
      this.setState({ col: 'col-xl-12' })
      Event.emit('hide_games')
    } else {
      this.setState({ col: 'col-xl-9' })
      Event.emit('show_min_games')
    }

    if (isMobile()) {
      this.setState({ mobile: true })
    } else {
      this.setState({ mobile: false })
    }

    if (isMobile()) {
      this.setState({ padding: 'p-0', ovh: 'ovh' })
    }
  }
  render() {
    let { header, auth, mobile } = this.state
    let { content } = this.props // Pages / Games Contents
    let wallet // Show Wallet if User don't Have Cash

    try {
      wallet = this.props.get_wallet.show
    } catch (e) {}

    const { t } = this.props
    return (
      <>
        {header}
        <div className="page-wrapper">
         
          <div className="page-content container pb-3" id={'page-content'}>
              <div className='mb-0 p-0 show-mobile h2-play mt-2'>
                <div className='row p-0'>
                    <div className='col-12'>
                    <Menus />
                    </div>
                    {/* <div className='col-6'>
                        <a href='tel:0101290080' className='float-right mt-2 pr-2' style={{fontSize:'12px'}}><i className='mdi mdi-phone'></i>: 0101 290 080</a>
                        </div> */}
                </div>
              </div>
              <div className='carousel-section show-mobile'>
                <Carousel />
            </div>

            <div className="m-0 p-0 show-mobile">
              <Mobi mobile={this.state.mobile} />
            </div>
{/*             
            <div className='container bottom-section mt-2 show-mobile' style={{padding:'4px 0'}}>
            <div className=" row">
                <div className="col-md-8 hide-mobile">
                  <div className='row m-0 p-0'>
                    <div className='col-md-12 m-0 p-0 '>
                      <Queue t={this.props.t} />
                    </div>
                  </div>
                   
                </div>
                <div className="col-md-4" >
                  <Bet
                    mobile={this.state.mobile}
                    token={this.state.token}
                    isLogged={this.state.isLogged}
                  />
                </div>
            </div>
           </div> */}

            {wallet && <WalletAlert t={t} uid={this.props.get_wallet.uid} />}
            {auth === true && <Login t={t} justModal="true" />}
            <div className="content-section">
              <div className=" row m-0 p-0">

                <div className="col-md-4 m-0 p-0 hide-mobile pr-1">
                  <div className="clear-bg " style={{border: '1px solid #414453',height:'1030px'}}>
                    <div className="m-0 p-0">
                      <Players t={this.props.t} />
                    </div>
                    {/* <div className="m-0 p-0">
                      <img className="d-block w-100 padded" src="/assets/images/cheza.jpg" alt="Cheza na scorepesa"  />
                    </div> */}
                  </div>
                </div>
                
                <div className="col-md-8 m-0 pl-0 pr-1">
                  <div className='col-md-12 m-0 p-0'>
                    <div className="row m-0 p-0 hide-mobile">
                      <Canvas mobile={this.state.mobile} />
                    </div>
                  </div>

                  <div className='row padded'>
                    <div className='container-fluid'></div>
                    <div className="col-md-12 col-sm-12 m-0"  /*style={{backgroundColor:'#00295f'}}*/>
                      <div className='row d-bets mt-2'>
                      <Bet
                        mobile={this.state.mobile}
                        token={this.state.token}
                        isLogged={this.state.isLogged}
                        bid='0'
                      />
                      </div>
                    </div>
                    {/* <div className="col-md-6 col-sm-12 m-0 pr-1">
                      <div className='row d-bets mt-2'>
                      <Bet2
                        mobile={this.state.mobile}
                        token={this.state.token}
                        isLogged={this.state.isLogged}
                        bid='1'
                      />
                      </div>
                    </div> */}

                  </div>

                  {/*<div className='carousel-section hide-mobile'>
                      <Carousel />
                  </div>*/}

                  <div className='container bottom-section-mobile mt-1  hide-mobile'>
                    <div className='row m-0 p-0'>
                      <div className='col-md-12 m-0 p-0 '>
                        <Queue t={this.props.t} />
                      </div>
                    </div>
                  </div>

                </div>  
              </div>

              {/* <div className="history_ hide-mobile">
                <HistoryList game={'crash'} t={this.props.t} />
              </div> */}
            </div>

           <div className='container bottom-section-mobile-2 mt-2 show-mobile'>
            <div className=" row">
                <div className="container-fluid">
                  <div className='row m-0 p-0'>
                    {/* <div className='col-md-1 m-0 p-0 '>
                        <img src="/assets/images/avatar.svg" style={{width:'100%',marginTop:'40px'}} alt="profile-user"/>
                        <p className='text-center'>Profile</p>
                    </div> */}
                    <div className='col-md-12 m-0 p-0 '>
                      <Queue2 t={this.props.t} />
                    </div>
                  </div>
                   
                </div>
            </div>
           </div>

          <div className='footer-section pb-2'>
            <div className="row text-lightt">
              <div className="col-md-4" style={{marginBottom:'4px'}}>
                <div className="footer-card  text-center foot-line">
                  {/* <h4>Lipa na Mpesa</h4>
                  <div>Paybill Number: 0000420</div> */}
                  <h3>Support</h3>

                  <div>
                     <a href="https://wa.me/0101290080" target="_blank">
                      <i className= {'mdi mdi-whatsapp'} style={{color:'#34B7F1'}}></i> 
                        {/* <img
                          src="/assets/images/icons/kes.svg"
                          className="btn-icon"
                        ></img>{' '} */}
                          0101 290 080
                      </a>
                    </div>
                </div>
              </div>

              <div className="col-md-4" style={{marginBottom:'4px'}}>
                <div className="footer-card text-center foot-line">
                <h4>Quick Links</h4>
                  <ul>
                    <li>How to Play</li>
                    <li><a href="#"> Terms & Conditions</a></li>
                    <li><a href="#">{/* Privacy Policy */}<PrivacyPolicy t={t} /></a></li>
                    <li>18+ Responsible Betting</li>
                    <li></li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4" style={{marginBottom:'4px'}}>
                <div className="footer-card text-center foot-line">
                  <h4>Social Media</h4>
                    {/* <ul className='list-group'>
                      <li className='text-centre'>
                        <i class="mdi mdi-facebook fb"></i> Facebook
                      </li>
                      <li className='text-centre'>
                        <i class="mdi mdi-twitter twta"></i> Twitter
                      </li>
                      <li className='text-centre'>
                        <i class="mdi mdi-instagram insta"></i> Instagram
                      </li>
                    </ul> */}
                      <ul className='socials'>
                        <li><a href="#"><i class="mdi mdi-facebook fb"></i> Facebook</a></li>
                        <li><a href="#"><i class="mdi mdi-twitter twta"></i> Twitter</a></li>
                        <li><a href="#"><i class="mdi mdi-instagram insta"></i> Instagram</a></li>
                        <li><a href="#"><i class="mdi mdi-instagram insta"></i> Tiktok</a></li>
                      </ul>
                </div>
              </div>            
            </div>

            <div className="row text-lightt">
              <div className='container-fluid'>
                <p className='text-centre'>&copy; 2023 Kapamia | All Rights Reserved</p>
              </div>
            </div>

          </div>
          </div>
        </div>
      </>
    )
  }
}

Index.propTypes = {
  get_wallet: PropTypes.string,
}

const mapStateToProps = (state) => ({
  get_wallet: state.items.get_wallet,
})

export default connect(mapStateToProps, null)(Index)
