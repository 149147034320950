import React, { useEffect, useState, useRef } from 'react';
import {Popover, OverlayTrigger, Overlay, Tooltip} from "react-bootstrap";
import {__, forceSatoshiFormat} from "../../../../Helper";
import stroage from "../../../../Storage";
  
function CreditRange(props) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const credit = stroage.getKey('credit');

  const handleClick = (event) => {
    event.preventDefault()
    setShow(!show);
    setTarget(event.target);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const style = {
      borderColor: "transparent",
      /*borderWidth: "1px",*/
      borderStyle: "solid",
      borderRadius: '2px'
  }

  return (
    <div ref={ref}>
     
            <div class="input-group range-btns" style={style}>
              <span class="input-group-prepends">
                <button type="button" class="btn btn-cs btn-xs glow" onClick={ e => props.min(e) }>20</button>
              </span>
              <span class="input-group-prepends">
                <button type="button" class="btn btn-cs btn-xs glow" onClick={ e => props.multi(e) }>50</button>
              </span>
              <span class="input-group-prepends">
                <button type="button" class="btn btn-cs btn-xs glow" onClick={ e => props.devide(e) }>100</button>
              </span>
              <span class="input-group-appends">
                <button type="button" class="btn btn-cs btn-xs glow" onClick={ e => props.max(e) }>200</button>
              </span>
            </div>
      </div>
  );
}

export default CreditRange;