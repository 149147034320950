import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import Index from "./App/Pages";
import NotFound from "./App/Pages/404";
import Support from "./App/Pages/Support";
import Affiliate from "./App/Pages/Affiliate";
import Bonus from "./App/Pages/Affiliate/Bonus";
import Register from "./App/Pages/Auth/Register";
import Login from "./App/Pages/Auth/Login";

import UserStat from "./App/Components/User/Stat";
import GameStat from "./App/Components/Game/Stat";
import UserSetting from "./App/Components/User/Setting";
import Leaderboard from "./App/Pages/Leaderboard";

import Crash from "./App/Games/Crash";

function Router(props) {
    let location = useLocation();
    let background = location.state && location.state.background;

    const { t } = props;

    return (
        <>
            <Switch history={props.history} location={background || location}>
                <Route exact path="/" children={<Index t={t} location={location} content={<Crash t={t} />} />} />
                
                <Route exact path="/leaderboard" children={<Index t={t} content={<Leaderboard t={t} />} />} />
                <Route exact path="/support" children={<Index t={t} content={<Support t={t} />} />} />
                {/* <Route exact path="/affiliate" children={<Affiliate t={t} content={<Affiliate t={t} history={props.history} params={location.search} />} />} /> */}
                <Route path="/affiliate/:code" component={Affiliate}/>
                <Route exact path="/register" children={<Register t={t} content={<Register params={location} history={props.history} t={t} />} />} />
                <Route exact path="/login" children={<Login t={t} content={<Login params={location} history={props.history} t={t} />} />} />
                <Route exact path="/aff" children={<Bonus t={t} content={<Bonus params={location} history={props.history} t={t} />} />} />
                <Route exact path="/setting" children={<Index t={t} content={<UserSetting t={t} />} />} />
                <Route exact path="/user/:id" children={<Index t={t} content={<UserStat t={t} params={location} />} />} />
                <Route exact path="/game/:id" children={<Index t={t} content={<GameStat t={t} params={location} />} />} />
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </>
    );
}

export default Router;