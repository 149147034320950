import React from "react";
import GameModal from "../../../Components/Game/Stat/Modal";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import Engine from "../Engine";
import { __, isMobile } from "../../../../Helper";

// class HistoryList extends React.Component {
//     constructor (props){
//         super(props);
//         this.state = {
//             games: [],
//             name: storage.getKey('name'),
//             token: storage.getKey('token'),
//             game: this.props.game,
//             gameRow: [],
//             engine: Engine
//         };
//     }

//     componentDidMount() {
//         let { engine } = this.state;
//         engine.trigger.on("busted_crash", data => this.busted(data));
//         engine.trigger.on("game_status", data => this.gameSync(data));
//     }

//     busted(data){
//         let busted  = (data.amount/100).toFixed(2);
//         let color   = (busted >= 1.5 ? 'bet-success' : 'bet-danger');

//         if(isMobile()){
//             if(this.state.gameRow.length === 5){
//                 this.state.gameRow.shift();
//             }
//         }
//         else {
//             if(this.state.gameRow.length === 10){
//                 this.state.gameRow.shift();
//             }
//         }

//         let add = this.state.gameRow.concat([
//             <li className={color} key={data.game_id} >
//                 <GameModal color={color} title={busted} game_id={data.game_id} font={13} />
//             </li>
//         ]);

//         this.setState({ gameRow: add });
//     }

//     gameSync(list){
//         // console.log(list.crashes);
//         if(list.crashes === undefined) return;
//         if(list.crashes.length === 0) return;

//         // __.reverse(list.crashes).forEach((array, i) => {
//         //     if(i < 10){
//         //         let busted  = (array.amount/100).toFixed(2);
//         //         let color   = (busted >= 1.5 ? 'bet-success' : 'bet-danger');
//         //         let row = <li className={color} key={i}>
//         //             <GameModal key={i} color={color} title={busted} game_id={array.game_id} font={13} />
//         //         </li>;
//         //         this.setState(state => ({ gameRow: [row, ...state.gameRow.reverse()] }));
//         //     }
//         // });

//         list.crashes.forEach((array, i) => {
//             if(i < 10){
//                 let busted  = (array.amount/100).toFixed(2);
//                 let color   = (busted >= 1.5 ? 'bet-success' : 'bet-danger');
//                 let row = <li className={color} key={i}>
//                     <GameModal key={i} color={color} title={busted} game_id={array.game_id} font={13} />
//                 </li>;
//                 this.setState(state => ({ gameRow: [row, ...state.gameRow] }));
//             }
//         });2
//     }

//     render () {
//         if(isMobile()){
//             if(this.state.gameRow.length >= 5)
//                 this.state.gameRow.length = 5;
//         }
//         else {
//             if(this.state.gameRow.length >= 10)
//                 this.state.gameRow.length = 10;
//         }
//         return(
//             <div className="nav font-14 font-weight-bold mybets">
//                 <ul>
//                     {this.state.gameRow}
//                 </ul>
//             </div>

//         );
//     }
// }

// export default HistoryList;

class HistoryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            games: [],
            name: storage.getKey('name'),
            token: storage.getKey('token'),
            game: this.props.game,
            gameRow: [],
            engine: Engine
        };
    }

    componentDidMount() {
        let { engine } = this.state;
        engine.trigger.on("busted_crash", data => this.busted(data));
        engine.trigger.on("game_status", data => this.gameSync(data));
    }

    busted(data) {
        let busted = (data.amount / 100).toFixed(2);
        let color = busted >= 1.5 ? 'bet-success' : 'bet-danger';

        if (isMobile()) {
            if (this.state.gameRow.length === 5) {
                this.setState((prevState) => ({
                    gameRow: [...prevState.gameRow.slice(1), this.createGameModal(color, busted, data.game_id)]
                }));
            } else {
                this.setState((prevState) => ({
                    gameRow: [...prevState.gameRow, this.createGameModal(color, busted, data.game_id)]
                }));
            }
        } else {
            if (this.state.gameRow.length === 10) {
                this.setState((prevState) => ({
                    gameRow: [...prevState.gameRow.slice(1), this.createGameModal(color, busted, data.game_id)]
                }));
            } else {
                this.setState((prevState) => ({
                    gameRow: [...prevState.gameRow, this.createGameModal(color, busted, data.game_id)]
                }));
            }
        }
    }

    gameSync(list) {
        if (list.crashes === undefined) return;
        if (list.crashes.length === 0) return;

        this.setState((prevState) => {
            const newGameRow = list.crashes
                .slice(0, 10)
                .map((array, i) => {
                    const busted = (array.amount / 100).toFixed(2);
                    const color = busted >= 1.5 ? 'bet-success' : 'bet-danger';
                    return this.createGameModal(color, busted, array.game_id, i);
                });

            return { gameRow: [...newGameRow, ...prevState.gameRow.slice(0, 9)] };
        });
    }

    createGameModal(color, busted, game_id, key) {
        return (
            <li className={color} key={key}>
                <GameModal key={key} color={color} title={busted} game_id={game_id} font={13} />
            </li>
        );
    }

    render() {
        if (isMobile()) {
            if (this.state.gameRow.length >= 5)
                this.state.gameRow.length = 5;
        }
        else {
            if (this.state.gameRow.length >= 10)
                this.state.gameRow.length = 10;
        }
        return (
            <div className="nav font-14 font-weight-bold mybets">
                <ul>
                    {this.state.gameRow}
                </ul>
            </div>
        );
    }
}

export default HistoryList;
