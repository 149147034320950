import Swal from "sweetalert2";
import storage from "../../../Storage";
import socket from "../../../Socket";
import {Event, encode, decode, forceSatoshiFormat, randomString,wekeza} from "../../../Helper";
import C from "../../../Constant";

/**
 * Game Engine
 */
function Engine(){

    var self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Gamr Name
     */
    self.game = 'crash';

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = Event;
    // self.trigger.setMaxListeners();

    /**
     * Intialize Player
     * @type {boolean}
     */
    self.init = false;

    /**
     * Current Player
     * @type {null}
     */
    self.token = null;

    /**
     * Script Form Data
     * including 2 value ( amount / Payout )
     * name / value / type
     * @type {Array}
     */
    self.formData = [];

    /**
     * Amount for the round
     * @type {null}
     */
    self.amount = null;

    /**
     * Payout value for the round
     * @type {null}
     */
    self.payout = null;

    /**
     * Target User for following
     * @type {null}
     */
    self.targetUser = null;

    /**
     * Game Status
     * 3 type: waiting | started | busted
     */
    self.gameStatus = null;

    /**
     * Player coin
     */
    self.coin = 'kshs';

    /**
     * Player Status
     * 3 type: playing | Waiting | null
     */
    self.playerStatus = null;

    /**
     * Game History
     */
    self.history = [];

    /**
     * User Is Playing Game
     */
    self.isPlaying = false;

    /**
     * User Is Holding for Game
     */
    self.isHolding = false;

    /**
     * Game Options
     */
    self.time = null
    self.md5 = null;
    self.amount = null;
    self.force = null;
    self.our_gid = null;
    self.bid = null;

    /**
     * Event on Getting Status game
     */
    self.ws.on(C.STATUS_CRASH, function (data) {
        data =  decode(data);

        self.gameStatus = data.status;
        self.history.push(data.crashes);
        self.time = data.time
        data.md5 = (data.md5 !== undefined) ? data.md5: null;
        data.force = data.force;
        data.amount = data.amount;
        self.trigger.emit('game_status', data);
    });

    /**
     * Event on Getting History
     */
    self.ws.on(C.HISTORY_CRASH, function (data) {
        data =  decode(data);
        self.trigger.emit(C.HISTORY_CRASH, data);
    });

    /**
     * Event on Getting All Players
     */
    self.ws.on(C.PLAYERS_CRASH, function (data) {
        data =  decode(data);
        self.trigger.emit('game_players', data);
    });

    /**
     * Event on Playing Game by User
     */
    self.ws.on(C.PLAY_CRASH, function (data) {
        data = decode(data);
        self.our_gid = data.our_gid;
        self.isPlaying = true;
        self.trigger.emit('play_crash', data);
    });

    self.ws.on(C.PLAY_CRASH_1, function (data) {
        data = decode(data);
        console.log(data);
        self.our_gid = data.our_gid;
        self.isPlaying = true;
        self.trigger.emit('play_crash_1', data);
    });

    /**
     * Event on CashOut Game by User
     */
    self.ws.on(C.FINISH_CRASH, function (data) {
        data = decode(data);
        self.isPlaying = false;
        self.trigger.emit('finish_crash', data);
    });

       /**
     * Event on CashOut Game by User
     */
       self.ws.on(C.FINISH_1_CRASH, function (data) {
        data = decode(data);
        self.isPlaying = false;
        self.trigger.emit('finish_1_crash', data);
    });

    /**
     * Event on busted game
     */
    self.ws.on(C.BUSTED_CRASH, function (data) {
        data = decode(data);
        self.isPlaying = false;
        self.gameStatus = 'busted';
        // self.history.unshift(data);
        self.history.unshift(data);
        self.time = data.time
        data.md5 = (data.md5 !== undefined) ? data.md5: null;
        data.force = data.force;
        data.amount = data.amount;
        self.trigger.emit('busted_crash', data);
        self.trigger.emit('busted_crash_history', data);
    });

    /**
     * Event on started game
     */
    self.ws.on(C.STARTED_CRASH, function (data) {
        data = decode(data);

        self.gameStatus = 'started';
        self.time = data.time
        data.md5 = (data.md5 !== undefined) ? data.md5: null;
        data.force = data.force;
        data.amount = data.amount;
        self.trigger.emit('started_crash', data);
    });

    /**
     * Event on waiting game
     * Script Excution from here
     */
    self.ws.on(C.WAITING_CRASH, function (data) {
        data = decode(data);
        self.gameStatus = 'waiting';
        self.time = data.time
        data.md5 = (data.md5 !== undefined) ? data.md5: null;
        data.force = data.force;
        data.amount = data.amount;
        self.trigger.emit('waiting_crash', data);
    });

    /**
     * Event on Error
     */
    self.ws.on(C.ERROR_CRASH, function (data) {
        data = decode(data);
        self.trigger.emit('error_crash', data);
    });
}

/**
 * Add Player for playing game
 */
Engine.prototype.play = function () {
    let self = this;

    if(self.amount < 10.00){
        self.amount = forceSatoshiFormat(10.00);
    }

   console.log(encode({
    token: self.token,
    amount: self.amount,
    payout: self.payout,
    coin: 'kshs',
    odd: self.odd,
    bid: self.bid,
   }));

    self.ws.emit(C.PLAY_CRASH, encode({
        token: self.token,
        amount: self.amount,
        payout: self.payout,
        coin: 'kshs',
        odd: self.odd,
        bid: self.bid
    }));
}

Engine.prototype.play2 = function () {
    let self = this;

    if(self.amount < 10.00){
        self.amount = forceSatoshiFormat(10.00);
    }

   console.log(encode({
    token: self.token,
    amount: self.amount,
    payout: self.payout,
    coin: 'kshs',
    odd: self.odd,
    bid: self.bid,
   }));

    self.ws.emit(C.PLAY_CRASH_1, encode({
        token: self.token,
        amount: self.amount,
        payout: self.payout,
        coin: 'kshs',
        odd: self.odd,
        bid: self.bid
    }));
}


/**
 * Payout / Finish Round
 */
Engine.prototype.finish = function (time) {
    let self = this;
    let crypt = wekeza('iKfnJJvdUTZ2I35c4BFuvVGp3efU(%',time);
    let timestamp = crypt.crypt;
    let token_2 = crypt.token2;
    // let bid =  self.our_gid.slice(-1);
    console.log( 'engine gid ' +self.our_gid);

    if(self.our_gid != undefined){
    let gid = self.our_gid.split('_')[0];
    let bid = '0';
    
    console.log(
        encode({
            token: self.token,
            token2: token_2,
            crypt: timestamp,
            // our_gid: self.our_gid
            our_gid: gid+'_'+ bid,
            // bid : bid
        })
    );
    
    self.ws.emit(C.FINISH_CRASH, encode({
        token: self.token,
        token2: token_2,
        crypt: timestamp,
        our_gid: gid+'_'+ bid
    }));
}
}

Engine.prototype.finish2 = function (time) {
    let self = this;
    let crypt = wekeza('iKfnJJvdUTZ2I35c4BFuvVGp3efU(%',time);
    let timestamp = crypt.crypt;
    let token_2 = crypt.token2;
    // let bid =  self.our_gid.slice(-1);

    console.log( 'engine gid ' +self.our_gid);

    if(self.our_gid != undefined){
    let gid = self.our_gid.split('_')[0];
    let bid = '1';
    
    console.log(
        encode({
            token: self.token,
            token2: token_2,
            crypt: timestamp,
            // our_gid: self.our_gid
            our_gid: gid+'_'+ bid,
            // bid : bid
        })
    );

    
    self.ws.emit(C.FINISH_1_CRASH, encode({
        token: self.token,
        token2: token_2,
        crypt: timestamp,
        our_gid: gid+'_'+ bid
        // our_gid: self.our_gid
    }));
}
}

/**
 * Get Game Status
 */
Engine.prototype.getStatus = function () {
    let self = this;
    self.ws.emit(C.STATUS_CRASH);
}

/**
 * Get Game Players
 */
Engine.prototype.getPlayers = function () {
    let self = this;
    self.ws.emit(C.PLAYERS_CRASH);
}

/**
 * Get the Game History
 */
Engine.prototype.getHistory = function () {
    let self = this;
    self.ws.emit(C.HISTORY_CRASH);
}

/** Start Engine **/
export default new Engine();